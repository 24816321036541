import React from "react";

const Footer = (props) => {
    /* Time by market */
    const getLocalTime = (location) => {
        const d = new Date();
        const localTime = d.getTime();
        const localOffset = d.getTimezoneOffset() * 60000;
        const utc = localTime + localOffset;
        let offset = 0; // UTC of USA Eastern Time Zone is -05.00
        switch(location) {
            case "NEW_YORK":
                    offset = -4;
                break;
            case "LONDON":
                    offset = 1;
                break;
            case "CAPE_TOWN":
                    offset = 2;
                break;
            default:
                offset = -5;
        }
        const local = utc + (3600000 * offset);
        return new Date(local).toLocaleString([], {hour: '2-digit', minute:'2-digit'});
    }
    return (
    <>
         {/*FOOTER SECTION*/}
            <div className="">
            {/*CARDS FOR EACH OF THE LOCATIONS*/}
            
            <div className="lg:grid lg:grid-cols-5 mb-5 md:mb-10">
                <div className="p-5 md:p-10 relative ">
                    <div className="lg:border-r-2 lg:border-slate-800 pr-10 h-[100%]">
                        <p className="text-xl mb-2 md:mb-5 font-bold">
                            New York                 
                        </p> 
                        <p className="text-sm mb-1 md:mb-5">920 Broadway 3rd Floor New York, NY 10010</p>
                        <p className="text-xs font-bold absolute bottom-0">{getLocalTime('NEW_YORK')}</p>
                    </div>
                </div>
                <div className="p-5 md:p-10 relative">
                    <div className="lg:border-r-2 lg:border-slate-800 pr-10 h-[100%]">
                        <p className="text-xl mb-2 md:mb-5 font-bold">
                            Toronto                
                        </p> 
                        <p className="text-sm mb-1 md:mb-5">Remote</p>
                        <p className="text-xs font-bold absolute bottom-0">{getLocalTime('NEW_YORK')}</p>
                    </div>
                </div>
                <div className="p-5 md:p-10 relative ">
                    <div className="lg:border-r-2 lg:border-slate-800 pr-10 h-[100%] ">
                        <p className="text-xl mb-2 md:mb-5 font-bold">
                            London                
                        </p> 
                        <p className="text-sm mb-1 md:mb-5">23 Lonsdale Road London, NW6 6RA</p>
                        <p className="text-xs font-bold absolute bottom-0">{getLocalTime('LONDON')}</p>
                    </div>
                </div>
                <div className="p-5 md:p-10 relative">
                <div className="lg:border-r-2 lg:border-slate-800 pr-10 h-[100%]">
                    <p className="text-xl mb-2 md:mb-5 font-bold">
                        Durban                
                    </p> 
                    <p className="text-sm mb-1 md:mb-5">2 Heleza Blvd, Hillhead, uMhlanga, 4320</p>
                    <p className="text-xs font-bold absolute bottom-0">{getLocalTime('CAPE_TOWN')}</p>
                </div>
                </div>
                <div className="p-5 md:p-10 relative">
                <div className="pr-10 h-[100%]">
                    <p className="text-xl mb-2 md:mb-5 font-bold">
                        Cape Town                
                    </p> 
                    <p className="text-sm mb-1 md:mb-5">25 Bell Cres, Westlake, Cape Town, 7945</p>
                    <div className="text-xs font-bold absolute bottom-0">{getLocalTime('CAPE_TOWN')}</div>
                </div>
                </div>
            </div>
            {/*END CARDS SECTION*/}
            </div>
        {/*END FOOTER SECTION*/}
        {/*COPYRIGHT SECTION*/}
            <div className="text-xs text-center p-5">Copyright reserved © {new Date().getFullYear()}. Confidential intellectual property of Barrows North America Ltd.</div>
        {/*END COPYRIGHT SECTION*/}
        </>
    )
}

export default Footer;