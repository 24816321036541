import React, { useState} from "react";

import { HashLink as Link } from 'react-router-hash-link';

/* Custom imports */
import SideBarNav from "../../../controls/SideBarNav/SideBarNav";


/* MUI imports */
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/Button';
import { grey } from '@mui/material/colors';

import Footer from "../Footer/Footer.js";

const Privacy = () => {
    const body = document.getElementsByTagName('Body')[0];
    body.style.backgroundColor = "#000000";

    const imageDirectory = "https://assets.barrowsconnectedstore.com/home/images/";
    /* Time by market */
    const getLocalTime = (location) => {
        const d = new Date();
        const localTime = d.getTime();
        const localOffset = d.getTimezoneOffset() * 60000;
        const utc = localTime + localOffset;
        let offset = 0; // UTC of USA Eastern Time Zone is -05.00
        switch(location) {
            case "NEW_YORK":
                    offset = -4;
                break;
            case "LONDON":
                    offset = 1;
                break;
            case "CAPE_TOWN":
                    offset = 2;
                break;
            default:
                offset = -5;
        }
        const local = utc + (3600000 * offset);
        return new Date(local).toLocaleString([], {hour: '2-digit', minute:'2-digit'});
        }

        /* Side Bar Navigation */
    const [showSideBar, setShowSideBar] = useState(false);
    const toggleSideBar = () => {
        //console.log(lg({"sig":SIGNATURE, "function":"toggleSideBar"}));
        if(showSideBar === true) {
            setShowSideBar(false);
        } else {
            setShowSideBar(true);
        }
    }
    return (
    <div id="data-privacy" className="text-white">
         <SideBarNav 
                showSideBar={showSideBar}
                closeSideBar={toggleSideBar} />
    {/* START NAVIGATION SECTION */}
        <div className="bg-slate-800 flex items-center text-white min-h-[50px]" style={{"position":"fixed", "width":"100vw", "zIndex":300}}>
            <div className="menu-icon-container-v2"><IconButton onClick={()=> {toggleSideBar()}} variant="text" style={{color: grey[50]}}><MenuIcon className="menu-icon" /></IconButton></div>
            <div className=""><img className="logo-v2 min-w-[150px]" src={imageDirectory + "logo.png"} alt="Connected Store logo" /></div>
            <div className="grow text-xs md:text-md text-end mr-3"><Link  to="/?view=contact-us">CONTACT US</Link>&nbsp; | &nbsp; <a href="https://portal.barrows.tech/auth/login" target="_blank" rel="noreferrer">LOGIN</a></div>
        </div>
     <div className="flex flex-col items-center leading-6">
        <div  className="max-w-[1000px] mt-[50px] p-5">
        <div className="mb-[40px]">        
            <h1 className="text-3xl ">Our Commitment To Your Privacy</h1>
            <div className="leading-8">At Barrows, we prioritize your privacy and are committed to ensuring that any data collected through our in-store display network is handled responsibly. Our in-store display media insights are powered by various third-party technologies, all of which minimize the data they capture to what is necessary to help stores and brands assess the effectiveness of their media campaigns. Our in-store display media insights may use one or more of the following technologies provided by our third-party partners to collect certain information about you:</div>
        </div>
        <div className="ml-[20px] mb-[40px]">
        <div>
            <h2 className="text-xl">Passive WiFi Sensors</h2>
            <div className="mt-1">These sensors detect WiFi signals from nearby devices to determine when a shopper is close by. As part of their process, MAC addresses are captured but not stored.</div>
            <div className="mt-4">Please see our partner's site for further details regarding protecting your privacy.</div>
            <div><a href="https://www.bluezoo.io/privacy" target="_blank"><u>BlueZoo privacy policy</u></a></div>
        </div>
        <div>
            <h2 className="text-xl mt-10">Anonymous Camera Sensors</h2>
            <div className="mt-1">These sensors use cameras to anonymously process in-store images to determine when shoppers are close by and how long they dwell. As part of their process, in-store images similar to in-store security camera footage are processed but not stored, and there is no attempt to identify an individual.</div>
            <div className="mt-4">Please see our partner's site for further details regarding protecting your privacy.</div>
            <div><a href="https://quividi.com/privacy/" target="_blank"><u>Quividi privacy policy</u></a></div>
        </div>
        <div>
            <h2 className="text-xl mt-10">Mobile POI Data</h2>
            <div className="mt-1">We receive mobile point-of-interest (POI) data to understand the total number of users in the store and map those users back to census data to better understand the in-store audience.</div>
            <div className="mt-4">Please see our partner's site for further details regarding protecting your privacy.</div>
            <div><a href="https://www.unacast.com/privacy-policy" target="_blank"><u>Unacast privacy policy</u></a></div>
        </div>

        <div className="mt-10">Barrows does not use any of the data obtained by a third-party technology partner for its own purposes, such as to create profiles about you, or target you with personalized media. The data collected by our third-party technology partners is only shared with stores and brands in aggregate to better understand how in-store media impacts audience, dwell, and performance metrics.</div>
        </div>
        <div>
            <h2 className="text-3xl">Need More Information?</h2>
            <div className="leading-8">If you have any questions or concerns about our privacy practices, please feel free to contact us: <a className="underline" href="/?view=contact-us">Contact Form</a>.</div>
        </ div>
        </div>
        </div>
        {/*FOOTER SECTION*/}
            <Footer></Footer>
        {/*END FOOTER SECTION*/}
</div>
    )
}

export default Privacy;