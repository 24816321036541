import React, {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import './SideBarNav.css'
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Cancel from '@mui/icons-material/Cancel';
import { IconButton } from '@mui/material';
import { grey } from '@mui/material/colors';
import { HashLink as Link } from 'react-router-hash-link';
import { v4 as uuid } from "uuid";

const SideBarNav = (props) => {

        let backgroundColor = props.backgroundColor ? props.backgroundColor : 'bg-slate-700';
        let showSideBar = props.showSideBar ? props.showSideBar : false;
        let closeSideBar = props.closeSideBar ? props.closeSideBar : null;
        const navigate = useNavigate();
        
        const MenuButton = styled(Button)({
            boxShadow: 'none',
            textTransform: 'none',
            fontSize: 18,
            color: '#ffffff',
            padding: '6px 12px',
            border: '1px solid',
            lineHeight: 1.5,
            borderWidth: "0px",
            fontFamily:["Poppins"],
            '&:hover': {
    
            },
            '&:active': {
             
            },
            '&:focus': {
              
            },
          })
        let menu = [
            {
                "title":"Home",
                "type": "anchor",
                "url":"/"
            },
            {
                "title":"About",
                "type": "anchor",
                "url":"/?view=about"
            },
            {
                "title":"Network",
                "type": "anchor",
                "url":"/?view=network"
            },
            {
                "title":"Gallery",
                "type": "anchor",
                "url":"/?view=gallery"
            },
            {
                "title":"Contact Us",
                "type": "anchor",
                "url":"/?view=contact-us"
            },
            {
                "title":"Data Privacy",
                "type": "anchor",
                "url":"/data-privacy"
            },
            {
                "title":"Login",
                "url":"https://portal.barrows.tech"
            }
    
        ]
        
        const closeMenu = () => {
            
            let sideBarNav = document.querySelector("#SideBarNav");
                sideBarNav.classList.remove("slideIn");
                sideBarNav.classList.add("slideOut");
            if(closeSideBar) {
                
                closeSideBar()
            }
            
        }
        const getUID = () => {
            return uuid();
        }
        // let menuItems = menu.map((item) =>
        //     <li key={getUID()} className='menu-item  hover:border-b-2 border-b-white'><Link  to={item.url}><MenuButton variant="text" onClick={() => {
        //         closeMenu();
        //     }} className='text-white'>{item.title}</MenuButton></Link></li>
        // );

        let menuItems = menu.map((item) =>
            <li key={getUID()} className='hover:border-b-2 border-b-white ml-5 mb-2 cursor-pointer h-[30px]' onClick={()=>{handleNav(item.url)}}>{item.title}</li>
        );

        const handleNav = (page) => {
            closeMenu();
            window.location.replace(page);
        }

        useEffect(() => {
            if(showSideBar) {
                let sideBarNav = document.querySelector("#SideBarNav");
                sideBarNav.classList.remove("slideOut");
                sideBarNav.classList.add("slideIn");
            }
        })
        return(
            <div id="SideBarNav" className='side-bar-nav text-white'>
                <div className={backgroundColor + ' nav-inner'}>
                    <div className='close-button text-sm'><IconButton onClick={() => {
                        closeMenu();
                    }} className='large-icon' aria-label="delete" size="large" style={{color: grey[50], width:"50px", height:"50px"}}>
                            <Cancel />
                        </IconButton>
                    </div>
                    <ul className='cursor-pointer mt-20'>{menuItems}</ul>
                </div>
            </div>
        )
}

export default SideBarNav;